<template>
    <div class="flex" style="flex-direction: column; height: 100%">
        <sm-card title="筛选查询" noHeader icon="el-icon-search" style="height: 80px">
            <el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
                <el-row class="flex-1">
                    <el-col :span="6">
                        <el-form-item label="查看企业：">
                            <el-select v-model="searchParm.entId" clearable :popper-append-to-body="false" placeholder="请选择"
                                @change="handleChange">
                                <el-option v-for="item in ents" :key="item.abbr" :label="item.abbr"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" >
                        <el-form-item label="设备名称：">
                            <el-select v-model="searchParm.unitId" clearable :popper-append-to-body="false"
                                placeholder="请选择"  @change="$forceUpdate()">
                                <el-option v-for="item in treeData" :key="item.id" :label="item.name"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div>
                    <el-button type="primary" @click="list()">查询</el-button>
                    <el-button plain @click="clearSearch()">重置</el-button>
                </div>
            </el-form>
        </sm-card>
        <div class="tableModule">
            <div class="title">
                <div class="title-left">
                    <i class="el-icon-s-operation"></i>
                    <span>实际转速</span>
                </div>
                <div class="title-right"><el-button type="primary" size="medium" @click="insert()"
                        style="margin-right: 8px">新增实际转速</el-button></div>
            </div>
            <div class="tableContainer">
                <div class="table" ref="tableContainer">
                    <div>
                        <el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
                            :header-cell-style="{ 'background-color': '#f9fafc' }" :cell-style="{ padding: 0 }"
                            style="margin-bottom: 20px">
                            <el-table-column prop="id" align="center" label="id" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="entName" align="center" label="企业名称"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column prop="unitName" align="center" label="单体名称"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column prop="actualSpeed" align="center" label="设定转速"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column align="center" label="时间段" show-overflow-tooltip width="400">
                                <template slot-scope="scope">
                                    <div>

                                        <span>{{ formatTimestamp(scope.row.startTime) }}</span>
                                        <span> - </span>
                                        <span>{{ formatTimestamp(scope.row.endTime) }}</span>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column prop="create_time" align="center" label="添加日期"
                                show-overflow-tooltip></el-table-column>


                            <el-table-column fixed="right" align="center" label="操作" width="230">
                                <template slot-scope="scope">
                                    <el-button type="text" @click="edit(scope.row)">编辑</el-button>
                                    <el-button type="text" @click="deletes(scope.row)">删除</el-button>

                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination style="float: right" @current-change="handleCurrentChange"
                        :current-page.sync="searchParm.page" :page-size="searchParm.pageSize"
                        layout="total, prev, pager, next,jumper" :total="tableData.total"></el-pagination>
                </div>
            </div>
        </div>
        <insert :isShow.sync="showInsertDialog" :dialogObj="insertObj"></insert>
    </div>
</template>
<script>
import smCard from '@c/smCard.vue';
import insert from './insert.vue';
export default {
    components: {
        smCard,
        insert,
    },
    data() {
        return {
            tableHeight: 0,
            tableData: {
                records: []
            },
            searchParm: {
                pageSize: 20,
                page: 1,
                entId: '',
                unitId: ''
            },
            showInsertDialog: false,
            insertObj: {},
            ents: [],
            treeData: [],
        };
    },
    created() {
        this.getEnts();
    },
    methods: {
        formatTimestamp(timestamp) {
            var date = new Date(timestamp);

            // 获取年、月、日、时、分、秒
            var year = date.getFullYear();
            var month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份是从0开始计数的，所以要加1，并且确保是两位数
            var day = ('0' + date.getDate()).slice(-2); // 确保是两位数
            var hours = ('0' + date.getHours()).slice(-2); // 确保是两位数
            var minutes = ('0' + date.getMinutes()).slice(-2); // 确保是两位数
            var seconds = ('0' + date.getSeconds()).slice(-2); // 确保是两位数

            // 将时间组合成指定格式
            var formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;

            return formattedDate;
        },
        //自适应表格高度
        calHeight() {
            this.$nextTick(() => {
                const rect = this.$refs.tableContainer.getBoundingClientRect();
                this.tableHeight = rect.height;
            });
        },
        //获取企业列表
        getEnts() {
            this.$get('/backend-api/sys/ent/list', {
                size: 99
            }).then(res => {
                if (res.code == 1000) {
                    this.ents = res.data.records;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleChange(value) {
            this.searchParm.unitId = '';
            this.getUnitList(value);

        },
        getUnitList(value) {
            this.$get('/backend-api/eqp/set/unit/list', { ent_id: value, size: 999, current: 1 }).then(res => {
                if (res.code != 1000) {
                    this.$message.error(res.msg);
                    return;
                }
                this.treeData = res.data.records;
            });
        },
        //清空搜索
        clearSearch() {
            this.searchParm = {
                pageSize: 20,
                page: 1,
                entId: '',
                unitId: ''
            };
            this.list();
        },
        //列表数据
        list() {
            if (this.searchParm.entId == '') {
                // 删除指定属性
                delete this.searchParm.entId;
            }
            if (this.searchParm.unitId == '') {
                delete this.searchParm.unitId;
            }
            this.$get('/backend-api/backend/api/v1/eqp-set-unit-actual-speed', this.searchParm).then(res => {
                if (res.code == 1000) {
                    this.tableData = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        //打开新增弹窗
        insert() {
            this.insertObj['type'] = 1;
            this.showInsertDialog = true;
        },
        edit(row) {
            this.insertObj = { ...row };
            this.insertObj['type'] = 2;
            this.insertObj['startTime'] = this.formatTimestamp(row.startTime);
            this.insertObj['endTime'] = this.formatTimestamp(row.endTime);
            console.log(this.insertObj);
            this.showInsertDialog = true;
        },
        //分页选择页面回调
        handleCurrentChange(val) {
            this.searchParm.page = val;
            this.list();
        },

        //删除
        deletes(row) {
            this.$confirm('是否确认删除？', '删除数据', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$remove('/backend-api/backend/api/v1/eqp-set-unit-actual-speed/' + row.id).then(res => {
                        if (res.code == 1000) {
                            this.$message.success('删除成功');
                            //刷新表格
                            this.list();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => { });
        }
    },
    mounted() {
        this.list();
        this.calHeight();
        window.addEventListener('resize', this.calHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calHeight);
    }
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
</style>
