<template>
    <el-dialog class="dialog" title="新增" :visible.sync="visiable" v-if="visiable">
        <div v-loading="loading" class="dlalog-body">
            <el-form ref="form" :rules="rules" :model="formData" label-width="180px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="查看企业：" prop="entId">
                            <el-select v-model="formData.entId" clearable :popper-append-to-body="false" placeholder="请选择"
                                @change="handleChange">
                                <el-option v-for="item in ents" :key="item.abbr" :label="item.abbr"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="设备名称：" prop="unitId">
                            <el-select v-model="formData.unitId" clearable :popper-append-to-body="false" placeholder="请选择"
                                @change="$forceUpdate()">
                                <el-option v-for="item in treeData" :key="item.id" :label="item.name"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="开始时间：" prop="startTime">
                                    <el-date-picker v-model="formData.startTime" value-format="yyyy-MM-dd HH:mm:ss"
                                        type="datetime" placeholder="请选择日期时间">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>

                            <el-col :span="12">
                                <el-form-item label="结束时间：" prop="endTime">
                                    <el-date-picker v-model="formData.endTime" value-format="yyyy-MM-dd HH:mm:ss"
                                        type="datetime" placeholder="请选择日期时间">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="实际转速：" prop="actualSpeed"><el-input v-model="formData.actualSpeed"
                                type="number"></el-input></el-form-item>
                    </el-col>

                </el-row>


            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close()">关闭</el-button>
                <el-button type="primary" @click="submit()">确定</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import { dialogMixin } from '@m/dialogMixin';
export default {
    mixins: [dialogMixin],
    data() {
        return {
            title: '',
            loading: false,
            formData: {},
            treeData: [],
            ents: [],
            rules: {
                endTime: [
                    {
                        required: true,
                        message: '请选择日期时间',
                        trigger: 'change'
                    }
                ],
                startTime: [
                    {
                        required: true,
                        message: '请选择日期时间',
                        trigger: 'change'
                    }
                ],
                entId: [
                    {
                        required: true,
                        message: '请选择企业',
                        trigger: 'change'
                    }
                ],
                actualSpeed: [{ required: true, message: '请输入实际转速', trigger: 'change' }],
                unitId: [{ required: true, message: '请选择所属设备', trigger: 'change' }]
            }
        };
    },
    methods: {
        //打开弹窗
        open() {
            this.formData = {};
            if (this.dialogObj.type == 2) {
                this.formData = this.dialogObj;
                this.getUnitList(this.dialogObj.entId);
            }
            this.$nextTick(() => {
                this.$refs.form.clearValidate();
            });
            this.getEnts();
        },
        getUnitList(value) {
            this.$get('/backend-api/eqp/set/unit/list', { ent_id: value, size: 999, current: 1 }).then(res => {
                if (res.code != 1000) {
                    this.$message.error(res.msg);
                    return;
                }
                this.treeData = res.data.records;
            });
        },
        //获取企业列表
        getEnts() {
            this.$get('/backend-api/sys/ent/list', {
                size: 99
            }).then(res => {
                if (res.code == 1000) {
                    this.ents = res.data.records;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleChange(value) {
            this.formData.unitId = ''

            this.getUnitList(value);

        },
        //提交表单
        submit() {
            this.$refs['form'].validate(valid => {
                if (valid) {
                    this.loading = true;
                    var apiUrl = '/backend-api/backend/api/v1/eqp-set-unit-actual-speed';
                    var requestMethod = this.dialogObj.type == 1 ? this.$postJson : this.$putJson;

                    requestMethod(apiUrl, this.formData).then(res => {
                        if (res.code == 1000) {
                            this.loading = false;
                            this.$message.success('操作成功');
                            this.$parent.list();
                            this.close();
                        } else {
                            this.loading = false;
                            this.$message.error(res.msg);
                        }
                    });

                } else {
                    return false;
                }
            });
        },
        //关闭弹窗
        close() {
            this.visiable = false;
        }
    }
};
</script>
<style lang="scss" scoped>
.dialog {
    ::v-deep .cascader {
        display: block;
    }
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

::v-deep input[type='number'] {
    -moz-appearance: textfield;
}
</style>
